<template>
  <!--预解除隔离弹窗-->
  <el-dialog v-if="isArrange" title="预解除隔离" :visible.sync="isArrange" width="800px" :before-close="closeArrange">
    <div class="zctc">
      <p style="color:red; margin-bottom: 20px;">预解除隔离申请需管理员确认，确认后可解除隔离!</p>
      <el-form style="width:100%" :rules="rules" ref="redform" :model="zcredform" label-width="180px">

        <el-form-item label="申请预解除隔离时间：" prop="applyRelieveTime">
            <el-date-picker @change="changepicker" style="width:90%" v-model="zcredform.applyRelieveTime" type="datetime" placeholder="选择时间"
                format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>

        <el-form-item label="转出类型" prop="transferType">
          <el-radio-group @change="trasHome()" v-model="zcredform.transferType">
            <el-radio label="1">解除管控</el-radio>
            <el-radio label="2">送医治疗</el-radio>
           <!-- <el-radio :disabled="this..unselect==='3'" label="3">送往隔离点</el-radio> -->
           <el-radio label="4" :disabled="this.unselect==='4'">居家隔离</el-radio>
            <el-radio label="5" :disabled="this.unselect==='5'">居家监测</el-radio>
            <el-radio label="9">其他</el-radio>
          </el-radio-group>
        </el-form-item>


        <P style="color:red; margin-bottom: 20px;margin-left:100px" v-if="this.tipsList.length>0&&this.zcredform.transferType !== ''">{{this.tipsList.find(item =>{
           
           return item.transferType === this.zcredform.transferType
         }) ? this.tipsList.find(item =>{
          
          return item.transferType === this.zcredform.transferType
        }).tips : ''}}</P>


        <el-form-item label="转出目的地：" prop="transferDestination">
          <el-input v-if="zcredform.transferType==''||zcredform.transferType==null" placeholder="请输入" />
          <el-input v-if="zcredform.transferType == 1||zcredform.transferType ==2||zcredform.transferType ==9" style="width:90%" v-model="zcredform.transferDestination" placeholder="请输入" />

          <el-select v-if="zcredform.transferType == 4||zcredform.transferType ==5" clearable filterable size="medium" v-model="homeitem"
              @change="homeOrgChange($event)" placeholder="负责机构">
              <el-option v-for="(item,index) in homeList" :key="index" :label="item.orgName" :value="item.orgCode" />
            </el-select>

        </el-form-item>

        <el-form-item label="备注说明：" prop="remark">
          <el-input style="width:90%" v-model="zcredform.remark" placeholder="请输入" />
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeArrange">取 消</el-button>
      <el-button size="small" type="primary" @click="submitFrom">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getBusinessOrgList} from "../../../api/SystemManagement/index.js";
import { applyRelieveIsolateApi ,reliveControlTips} from '../../../api/QuarantineRegistration/quarantineRegistrationpop';
import constants from "../../../common/constants";

export default {
  name: "PrepareRelieve",
  components: {},
  data() {
    return {
      singleRemark:"",
      select:"",
      unselect:"", //提示语返回的不选中的类型
      tipsList:[], //提示语数组
      homeitem:"",
      homeList:[],
      isArrange: false,
      zcredform: {
        applyRelieveTime: new Date(),
        transferType: '',
        transferDestination: '',
        remark: "",
        transferDestinationCode:"" //根据居家来查找负责的社区结构
      },
      rules: {
        applyRelieveTime: [{ required: true, message: "请选择解除隔离时间", trigger: "blur" }],
        transferType: [{ required: true, message: "请选择转出类型", trigger: "blur" }],
        transferDestination: [{ required: true, message: "请输入转出目的地", trigger: "blur" }],
        remark: [{ required: true, message: "请填写备注", trigger: "blur" }]
      },
      transfertypeselect: constants.TRANSFER_TYPE_SELECT, //转出类型
      regIds: [],
      regId: null
    }
  },


  mounted(){
    this.homeOrgList()
  },

  methods: {
    // 点开弹窗
    async show(item) {
      // console.log(item,'打印item 预计解除的弹窗')
      // 如果点的单个预解除隔离，那么就是regId，如果是选择批量预解除，就是regIds
        if(Array.isArray(item)) {
          this.regIds = item.map(item => item.id);
        } else {
          this.regId = item.id;
          let params = {
            id:item.id,
            source: "1"
        }
        // 提示语
        let res = await reliveControlTips ({params});
        this.tipsList = res.data.data.tips; //获取到的提示语数组
        this.unselect = res.data.data.unselect;//不能选中的字段
        // this.zcredform.transferType = res.data.data.select;
        // console.log(this.select,'打印this.selectttttt')
        this.singleRemark = item.remark
        this.select = res.data.data.select
        
        // console.log(this.select,'this.select打印，有可能是数字，有可能为null')
        }
      this.isArrange = true;
      
      this.zcredform = {
        applyRelieveTime: new Date(), //默认显示当前时间
        transferType: this.select!==null? this.select : item.transferType,
        transferDestination: '',
        remark: this.singleRemark,
        transferDestinationCode:""
      }

      
      this.zcredform.applyRelieveTime = this.$moment(this.zcredform.applyRelieveTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },

    changepicker(){
      // console.log(this.zcredform.applyRelieveTime,'打印变化的时间')
    },

    closeArrange() {
      this.isArrange = false;
    },

    // 清空后选项
    trasHome(){

      // console.log('按钮选择的变化触发')
        this.homeitem = "";
        this.zcredform.transferDestination = "";

    },

     // 根据业务状态去查询机构
    async homeOrgList() {
      let params = { homeIsolation: 1 }
      let { data } = await getBusinessOrgList({ params });
      if (data.code == 200) {
        // console.log(data.data)
        this.homeList = data.data
      }
    },

    homeOrgChange(orgCode){
      // 获取当前下拉框选中的对象
      let dir = this.homeList.find((item) => item.orgCode === orgCode);
      this.zcredform.transferDestination = dir.orgName;
      this.zcredform.transferDestinationCode = dir.orgCode;
    },

  
    // 确定隔离
    async submitFrom() {
      let params = {
        ...this.zcredform,
        regId: this.regId,
        regIds: this.regIds.length > 0 ? this.regIds : null
      };
      let { data } = await applyRelieveIsolateApi(params);
      if (data.code == 200) {
        this.$message.success(data.msg);
        this.isArrange = false;
        this.$parent.queryDataInfo();
      } else {
        this.$message.error(data.msg);
      }
    },

  }
}
</script>

<style scoped lang="scss">
</style>
